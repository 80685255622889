import { resolveComponent as _resolveComponent, createTextVNode as _createTextVNode, createVNode as _createVNode } from "vue";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.error.cause.js";
import { mixins } from "@/plugins/mixins";
import { ElMessage } from "element-plus";
import 'element-plus/es/components/message/style/css'

import MapContainer from "@/components/MapContainer.vue";
export default {
  name: "park",
  mixins: [mixins],
  components: {
    MapContainer
  },
  data() {
    return {
      columns: [{
        label: '停车区编码',
        prop: 'parkCode'
      }, {
        label: '站点名称',
        prop: 'parkName'
      }, {
        label: '所属区域',
        prop: 'areaName'
      }, {
        label: '停车规范方式',
        customRender: props => {
          return props.row.parkSp === 0 ? props.row.spWay : '无';
        },
        width: '120'
      }, {
        label: '停车范围方式',
        prop: 'parkType',
        width: '120'
      }, {
        label: '站点容量',
        prop: 'parkCapacity'
      }, {
        label: '停车区',
        customRender: props => {
          return _createVNode(_resolveComponent("el-link"), {
            "type": "primary",
            "underline": false,
            "onClick": () => {
              this.getAddress(props.row);
            }
          }, {
            default: () => [_createTextVNode("\u67E5\u770B\u505C\u8F66\u533A"), _createVNode("i", {
              "className": "el-icon-view el-icon--right"
            }, null), _createTextVNode(" ")]
          });
        }
      }, {
        label: '状态',
        customRender: props => {
          if (this.$hasPermission('park_status')) {
            return _createVNode(_resolveComponent("el-switch"), {
              "modelValue": props.row.parkStatus,
              "onUpdate:modelValue": $event => props.row.parkStatus = $event,
              "loading": this.switchLoading,
              "before-change": () => {
                return new Promise((resolve, reject) => {
                  this.changeEnabled(props.row, resolve, reject);
                });
              }
            }, null);
          } else {
            return props.row.parkStatus === 0 ? '启用' : '禁用';
          }
        },
        width: '110'
      }, {
        label: '创建时间',
        prop: 'createTime'
      }, {
        label: '修改时间',
        prop: 'updateTime'
      }],
      topButtons: [{
        name: '新增',
        code: 'add',
        type: 'primary',
        click: this.parkAddR,
        permissions: ['park_add']
      }],
      linkButtons: [{
        name: '编辑',
        code: 'edit',
        type: 'primary',
        click: this.parkEdit,
        permissions: ['park_edit']
      }],
      searchItems: [{
        prop: 'parkStatus',
        label: '状态',
        type: 'select',
        items: [{
          name: "启用",
          value: 0
        }, {
          name: "禁用",
          value: 1
        }],
        itemProp: {
          label: 'name',
          value: 'value'
        }
      }, {
        prop: 'spWay',
        label: '规范方式',
        type: 'select',
        items: [{
          name: "道钉",
          value: '道钉'
        }, {
          name: "AI摄像头",
          value: 'AI摄像头'
        }, {
          name: "RFID",
          value: 'RFID'
        }],
        itemProp: {
          label: 'name',
          value: 'value'
        }
      }, {
        prop: 'parkType',
        label: '范围方式',
        type: 'select',
        items: [{
          name: "定位范围",
          value: '定位范围'
        }, {
          name: "区域范围",
          value: '区域范围'
        }],
        itemProp: {
          label: 'name',
          value: 'value'
        }
      }, {
        type: 'cascader',
        prop: 'parkArea',
        label: '服务区域',
        props: {
          checkStrictly: true,
          emitPath: false,
          value: 'id',
          label: 'name',
          lazy: true,
          lazyLoad: (node, resolve) => {
            const {
              level
            } = node;
            if (level === 0) {
              this.$api.uac.area.list({}).then(res => {
                if (res.code === 200) {
                  resolve(res.data);
                } else {
                  resolve([]);
                }
              });
            } else {
              resolve([]);
            }
          }
        }
      }],
      switchLoading: false,
      isAdd: false,
      address: null,
      parkType: '',
      type: 'LOOK'
    };
  },
  methods: {
    parkAddR() {
      this.$router.push({
        path: '/parkAdd'
      });
    },
    getAddress(row) {
      console.log(row);
      if (row.parkType === '定位范围') {
        this.parkType = '定位范围';
        this.address = {
          lng: row.parkLongitude,
          lat: row.parkLatitude,
          add: row.parkAdd,
          radius: row.parkRange
        };
      } else {
        this.parkType = '区域范围';
        this.address = {
          add: row.parkAdd,
          lng: row.parkLongitude,
          lat: row.parkLatitude,
          paths: row.paths
        };
      }
      this.isAdd = true;
    },
    parkEdit(row) {
      this.$router.push({
        path: '/parkAdd',
        query: {
          id: row.id
        }
      });
    },
    changeEnabled(user, resolve, reject) {
      this.switchLoading = true;
      const callBack = res => {
        if (res.code === 200) {
          ElMessage({
            message: res.message,
            type: 'success'
          });
          return resolve(true);
        } else {
          ElMessage.error(res.message);
          return reject(new Error('Error'));
        }
      };
      let parms = {};
      if (user.parkStatus) {
        parms = {
          parkStatus: 1,
          id: user.id
        };
        this.$api.business.park.changeStatus(JSON.stringify(parms)).then(callBack).finally(() => this.switchLoading = false);
      } else {
        parms = {
          parkStatus: 0,
          id: user.id
        };
        this.$api.business.park.changeStatus(JSON.stringify(parms)).then(callBack).finally(() => this.switchLoading = false);
      }
    }
  }
};